import React from 'react';
import { Link } from 'react-router-dom';
import LogoMark from '../../assets/logomark.png';
import {
  NavWrapper, NavList, NavLink, NavListItem, Logo,
} from './styles';

function Customnav() {
  return (
    <NavWrapper>
      <Link to="/">
        <Logo src={LogoMark} />
      </Link>
      <NavList>
        <NavListItem>
          <NavLink to="/privacy">Privacy</NavLink>
        </NavListItem>
        <NavListItem>
          <NavLink to="/terms">Terms</NavLink>
        </NavListItem>
      </NavList>
    </NavWrapper>
  );
}

export default Customnav;
