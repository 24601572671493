import React from 'react';
import { MainLogo, GooglePlayBadge, Wrapper } from './styles';
import Logo from '../../assets/logo-ver-light.png';
import GooglePlay from '../../assets/google-play-badge.png';

function Banner() {
  return (
    <Wrapper>
      <MainLogo src={Logo} />
      <a target="_blank" href="https://play.google.com/store/apps/details?id=com.phatgpt" rel="noreferrer">
        <GooglePlayBadge src={GooglePlay} />
      </a>
    </Wrapper>
  );
}

export default Banner;
