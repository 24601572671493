import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../assets/colors';

export const Wrapper = styled.div`
  background-color: black;
  position:fixed;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
  float: left;
  width: 35px;
  margin: 10px 15px;
`;

export const NavWrapper = styled.div`
  position: absolute;
  top: 0;
  background-color: ${colors.navy};
  width: 100%;
  padding: 10px;
  align-items: center;
  justify-content: center;
`;

export const NavList = styled.ul`
  list-style-type: none;
  margin: 10px 15px 10px 0;
  padding: 0;
  display: block;
  float: right;
`;

export const NavListItem = styled.li`
  display: inline-block;
`;

export const NavLink = styled(Link)`
  color: white;
  display: block;
  padding: 5px;
  text-decoration: none;
  font-family: Arial;
  font-size: 16px;
`;
