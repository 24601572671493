const colors = {
  hotpink: '#ff005c',
  deeppink: '#ff1493',
  mustard: '#fcd878',
  rust: '#D96C06',
  forest: '#228B22',
  slate: '#708090',
  navy: '#090f32',
  primary: '#33c6ac',
  lightGray: '#CCCCCC',
  dimGray: '#6D6D64',
};

export default colors;
