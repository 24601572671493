import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './styles';

function Container({ children }) {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  );
}

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Container;
