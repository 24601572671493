import React from 'react';
import Container from '../components/ui/container';
import CustomNav from '../components/ui/customnav';
import Banner from '../components/home/banner';

function Home() {
  return (
    <Container>
      <CustomNav />
      <Banner />
    </Container>
  );
}

export default Home;
